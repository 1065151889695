<template>
  <div
    class="bg-white px-8 invoice-detail-page"
    style="height: calc(100vh - 50px)"
  >
    <v-row v-if="pageLoaded">
      <v-col md="12" class="py-0">
        <v-row>
          <v-col md="6" class="d-flex align-center justify-start">
            <v-chip
              label
              outlined
              color="blue darken-4"
              class="font-weight-600"
              >{{ dbInvoice.barcode }}</v-chip
            >
            <v-chip
              v-if="dbInvoice?.visit_converted == 1"
              label
              color="orange"
              text-color="white"
              class="ml-2 text-uppercase font-weight-600"
              >Converted to Visit</v-chip
            >
            <v-chip
              label
              :color="dbInvoice?.status_relation?.color"
              :text-color="dbInvoice?.status_relation?.textcolor"
              class="ml-2 text-uppercase font-weight-600"
              >{{ dbInvoice?.status_relation?.text }}</v-chip
            >
          </v-col>
          <v-col md="6" class="d-flex align-center justify-end">
            <v-btn
              v-if="false"
              v-on:click="updateMoreAction('edit')"
              depressed
              color="cyan white--text"
              class="custom-bold-button"
              ><v-icon small left>mdi-pencil</v-icon>Edit</v-btn
            >
            <template
              v-if="dbInvoice?.more_actions && dbInvoice?.more_actions.length"
            >
              <template v-if="dbInvoice?.more_actions.length > 3">
                <v-menu
                  v-if="
                    dbInvoice?.more_actions && dbInvoice?.more_actions.length
                  "
                  transition="slide-y-transition"
                  bottom
                  content-class="custom-menu-list"
                  offset-y
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      depressed
                      color="cyan white--text"
                      class="custom-bold-button mx-2"
                      >More...
                      <v-icon small right>mdi-chevron-down</v-icon></v-btn
                    >
                  </template>
                  <v-list>
                    <template v-for="(more, index) in dbInvoice?.more_actions">
                      <v-list-item
                        link
                        v-on:click="updateMoreAction(more.action)"
                        :key="index"
                        :disabled="more.disabled"
                      >
                        <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                          <v-icon class="icon-default-blue">{{
                            more.icon
                          }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title
                          class="font-weight-500 font-size-14"
                          >{{ more.title }}</v-list-item-title
                        >
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
              </template>
              <template v-else>
                <v-btn
                  v-for="(more, index) in dbInvoice?.more_actions"
                  v-on:click="updateMoreAction(more.action)"
                  :key="index"
                  :disabled="more.disabled"
                  depressed
                  :color="more.color"
                  class="custom-bold-button mx-2"
                  ><v-icon small left>{{ more.icon }}</v-icon>
                  {{ more.title }}</v-btn
                >
              </template>
            </template>
            <v-btn v-on:click="goBack()" depressed class="custom-bold-button"
              ><v-icon small left>mdi-chevron-left</v-icon>Back</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col md="5">
        <v-row>
          <v-col md="12">
            <span
              v-on:click="routeToCustomer()"
              class="cursor-pointer color-custom-blue font-weight-700 font-size-19"
              >{{ dbInvoice?.customer_relation?.display_name }}</span
            >
          </v-col>
          <v-col md="6">
            <label class="my-0">Billing Location</label>
            <template v-if="dbInvoice?.billing_relation?.id">
              <p class="mb-0">
                {{ dbInvoice?.billing_relation?.property_address }}
              </p>
            </template>
            <label class="mt-3">Contact Details</label>
            <template v-if="dbInvoice?.billing_contact_person_relation?.id">
              <p class="mb-0">
                {{ dbInvoice?.billing_contact_person_relation?.display_name }}
              </p>
              <p class="mb-0">
                {{ dbInvoice?.billing_contact_person_relation?.primary_phone }}
              </p>
              <p class="mb-0 d-flex align-center">
                {{ dbInvoice?.billing_contact_person_relation?.primary_email }}
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="ml-2"
                      style="font-size: 20px"
                      :color="
                        dbInvoice.notify_billing_contact_person ? 'cyan' : ''
                      "
                    >
                      mdi-email
                    </v-icon>
                  </template>
                  <span v-if="dbInvoice.notify_billing_contact_person"
                    >Notification sent</span
                  >
                  <span v-else>Notification not sent.</span>
                </v-tooltip>
              </p>
            </template>
          </v-col>
          <v-col md="6">
            <label class="my-0">Site Location</label>
            <template v-if="dbInvoice?.property_relation?.id">
              <p class="mb-0">
                {{ dbInvoice?.property_relation?.property_address }}
              </p>
            </template>
            <label class="mt-3">Contact Details</label>
            <template v-if="dbInvoice?.property_contact_person_relation?.id">
              <p class="mb-0">
                {{ dbInvoice?.property_contact_person_relation?.display_name }}
              </p>
              <p class="mb-0">
                {{ dbInvoice?.property_contact_person_relation?.primary_phone }}
              </p>
              <p class="mb-0 d-flex align-center">
                {{ dbInvoice?.property_contact_person_relation?.primary_email }}
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="ml-2"
                      style="font-size: 20px"
                      :color="
                        dbInvoice.notify_property_contact_person ? 'cyan' : ''
                      "
                    >
                      mdi-email
                    </v-icon>
                  </template>
                  <span v-if="dbInvoice.notify_property_contact_person"
                    >Notification sent</span
                  >
                  <span v-else>Notification not sent.</span>
                </v-tooltip>
              </p>
            </template>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="7" class="gray-background">
        <table width="100%">
          <tr>
            <td
              colspan="2"
              class="color-custom-blue font-weight-700 font-size-19"
            >
              {{ dbInvoice?.title }}
            </td>
          </tr>
          <tr>
            <td class="font-weight-600" width="20%">Reference</td>
            <td class="font-weight-600">
              {{ dbInvoice?.customer_ref ?? "-" }}
            </td>
          </tr>
          <tr v-if="dbInvoice?.enquiry_relation">
            <td class="font-weight-600" width="20%">Enquiry</td>
            <td
              class="font-weight-600 cursor-pointer color-custom-blue"
              v-on:click="routeToEnquiry()"
            >
              {{ dbInvoice?.enquiry_relation?.barcode }} -
              {{ dbInvoice?.enquiry_relation?.title }}
            </td>
          </tr>
          <tr v-if="dbInvoice?.quotation_relation">
            <td class="font-weight-600" width="20%">Quotation</td>
            <td
              class="font-weight-600 cursor-pointer color-custom-blue"
              v-on:click="routeToQuotation()"
            >
              {{ dbInvoice?.quotation_relation?.barcode }} -
              {{ dbInvoice?.quotation_relation?.job_title }}
            </td>
          </tr>
          <tr v-if="dbInvoice?.visit_relation">
            <td class="font-weight-600" width="20%">Visit</td>
            <td
              class="font-weight-600 cursor-pointer color-custom-blue"
              v-on:click="routeToVisit()"
            >
              {{ dbInvoice?.visit_relation?.barcode }} -
              {{ dbInvoice?.visit_relation?.title }}
            </td>
          </tr>
          <tr>
            <td class="font-weight-600" width="20%">Payment Due</td>
            <td class="font-weight-600">
              {{ dbInvoice?.payment_due_text ?? "-" }}
            </td>
          </tr>
          <tr>
            <td class="font-weight-600" width="20%">Payable Amount</td>
            <td class="font-weight-600 red--text">
              {{ formatMoney(dbInvoice?.total) }}
            </td>
          </tr>
          <tr>
            <td class="font-weight-600" width="20%">Paid Amount</td>
            <td class="font-weight-600 green--text">
              {{ formatMoney(dbInvoice?.paid_amount) }}
            </td>
          </tr>
        </table>
        <table width="100%" class="custom-border-top">
          <tr>
            <td align="left" class="font-weight-600">Invoice Date</td>
            <td
              align="left"
              class="font-weight-600 custom-border-left custom-border-right"
            >
              Invoice Due Date
            </td>
            <td align="left" class="font-weight-600">Invoice Sent Date</td>
          </tr>
          <tr>
            <td align="left" class="font-weight-600">
              {{ dbInvoice?.date ?? "-" }}
            </td>
            <td
              align="left"
              class="font-weight-600 custom-border-left custom-border-right"
            >
              {{ dbInvoice?.due_date ?? "-" }}
            </td>
            <td align="left" class="font-weight-600">
              {{ dbInvoice?.sent_date ?? "-" }}
            </td>
          </tr>
        </table>
      </v-col>
      <v-col md="12">
        <v-tabs
          active-class="custom-tab-active"
          v-model="invoiceTab"
          background-color="transparent"
          color="cyan"
          class="custom-tab-transparent tab-sticky"
        >
          <v-tab class="font-size-14 font-weight-600 px-8" href="#overview">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/invoice.svg')" />
              <!--end::Svg Icon-->
            </span>
            Overview
          </v-tab>
          <v-tab class="font-size-14 font-weight-600 px-8" href="#line-item">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/line-item.svg')" />
              <!--end::Svg Icon-->
            </span>
            Line Items
          </v-tab>
          <v-tab class="font-size-14 font-weight-600 px-8" href="#visit">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/visit.svg')" />
              <!--end::Svg Icon-->
            </span>
            Visits
          </v-tab>
          <v-tab class="font-size-14 font-weight-600 px-8" href="#history">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/history.svg')" />
              <!--end::Svg Icon-->
            </span>
            History
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="invoiceTab" class="custom-border-top">
          <v-tab-item value="overview">
            <v-expansion-panels mandatory accordion>
              <v-expansion-panel>
                <v-expansion-panel-header class="grey lighten-4">
                  <v-card-title class="headline">
                    <h3
                      class="font-weight-600 custom-headline color-custom-blue font-size-14"
                    >
                      DESCRIPTION
                    </h3>
                  </v-card-title>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-4 custom-grey-border">
                  <div v-if="dbInvoice?.description" class="w-100">
                    {{ dbInvoice?.description }}
                  </div>
                  <div
                    v-else
                    class="text--secondary"
                    style="font-style: italic"
                  >
                    No Description
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class="grey lighten-4">
                  <v-card-title class="headline">
                    <h3
                      class="font-weight-600 custom-headline color-custom-blue font-size-14"
                    >
                      PAYMENT TERMS
                    </h3>
                  </v-card-title>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-4 custom-grey-border">
                  <div
                    class="w-100"
                    v-if="dbInvoice?.payment_terms"
                    v-html="dbInvoice?.payment_terms"
                  />
                  <div
                    v-else
                    class="text--secondary"
                    style="font-style: italic"
                  >
                    No Payment Terms
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class="grey lighten-4">
                  <v-card-title class="headline">
                    <h3
                      class="font-weight-600 custom-headline color-custom-blue font-size-14"
                    >
                      TERMS &amp; CONDITIONS
                    </h3>
                  </v-card-title>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-4 custom-grey-border">
                  <div
                    class="w-100"
                    v-if="dbInvoice?.term_conditions"
                    v-html="dbInvoice?.term_conditions"
                  />
                  <div
                    v-else
                    class="text--secondary"
                    style="font-style: italic"
                  >
                    No Terms &amp; Conditions
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class="grey lighten-4">
                  <v-card-title class="headline">
                    <h3
                      class="font-weight-600 custom-headline color-custom-blue font-size-14"
                    >
                      INTERNAL NOTES & ATTACHMENTS
                    </h3>
                  </v-card-title>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-4 custom-grey-border">
                  <v-row>
                    <v-col md="6" class="custom-border-right">
                      <label class="font-size-16 font-weight-600"
                        >Internal Note</label
                      >
                      <div class="w-100 pl-1 pt-2 custom-border-top-1px">
                        <div
                          v-if="dbInvoice?.admin_note"
                          v-html="dbInvoice?.admin_note"
                        />
                        <div
                          v-else
                          class="text--secondary"
                          style="font-style: italic"
                        >
                          No Internal Note
                        </div>
                      </div>
                    </v-col>
                    <v-col md="6" class="custom-border-left">
                      <label class="font-size-16 font-weight-600"
                        >Client Note
                        <span class="text--secondary font-weight-500"
                          >(visible on PDF)</span
                        ></label
                      >
                      <div class="w-100 pl-1 pt-2 custom-border-top-1px">
                        <div
                          v-if="dbInvoice?.client_note"
                          v-html="dbInvoice?.client_note"
                        />
                        <div
                          v-else
                          class="text--secondary"
                          style="font-style: italic"
                        >
                          No Client Note
                        </div>
                      </div>
                    </v-col>
                    <v-col md="12" class="custom-border-top mt-4">
                      <v-row>
                        <v-col md="6">
                          <label class="font-size-16 font-weight-600"
                            >Attachments ({{
                              dbInvoice.attachments.length
                            }})</label
                          >
                          <table width="100%" class="custom-border-top-1px">
                           
                            <tbody>
                              <tr
                                v-for="(row, index) in dbInvoice.attachments"
                                :key="`view-attach-${index}`"
                              >
                                <td
                                  valign="middle"
                                  width="80%"
                                  class="pr-2 py-2"
                                >
                                  {{ row.name }}
                              
                                </td>
                                <td
                               @click="downloadFile(row.file.url)"
                                  valign="middle"
                                  width="20%"
                                  align="right"
                                  class="pl-2 py-2"
                                >
                                  <v-icon color="cyan" class="mr-2"
                                    >mdi-download</v-icon
                                  >
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-tab-item>
          <v-tab-item value="line-item">
            <line-item-view />
          </v-tab-item>
          <v-tab-item value="visit">
            <internal-visit
              :customer-id="dbInvoice?.customer"
              :property-id="dbInvoice?.property"
              :invoice-id="dbInvoice?.id"
              detailType="tickets"
              visitType="all"
              :visit-status="0"
              detailRoute="visit.detail"
              internal
            />
          </v-tab-item>
          <v-tab-item value="history">
            <internal-history-detail type="invoice" :type_id="dbInvoice?.id" />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col md="12">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p class="text-center font-weight-600 font-size-19">Please Wait...</p>
        <v-progress-linear rounded indeterminate height="8" color="orange" />
      </v-col>
    </v-row>
    <confirm-dialog
      key="confirm-dialog-invoice"
      :common-dialog="confirmDialog"
      :dialog-width="dialogWidth"
    >
      <template v-slot:title> Confirmation </template>
      <template v-slot:body>
        <v-container class="px-10" fluid>
          <p class="font-weight-600 font-size-19">
            Are you sure, you want to
            <span class="font-weight-700">{{ getConfirmTitle() }}</span
            >?
          </p>
          <v-layout
            class="my-4"
            v-if="
              confirmType == 'mark_as_approved' ||
              confirmType == 'mark_as_rejected' ||
              confirmType == 'mark_as_accepted'
            "
          >
            <v-flex>
              <label class="font-weight-500 font-size-16 required"
                >Remark</label
              >
              <v-textarea
                v-model.trim="actionData.remark"
                auto-grow
                dense
                filled
                color="cyan"
                label="Remark"
                solo
                flat
                row-height="25"
              ></v-textarea>
            </v-flex>
          </v-layout>
          <v-layout class="mt-4" v-if="confirmType == 'mark_as_accepted'">
            <v-flex md12>
              <label class="font-weight-500 font-size-16"
                >Attachments (Max 5)</label
              >
              <table width="100%">
                <tbody>
                  <tr
                    v-for="(row, index) in actionData.attachments"
                    :key="index"
                  >
                    <td width="50%" class="pl-0 pr-2 py-2">
                      <v-file-input
                        prepend-inner-icon="mdi-attachment mdi-rotate-90"
                        placeholder="Click here to select file"
                        label="Click here to select file"
                        prepend-icon=""
                        color="cyan"
                        solo
                        flat
                        v-model="row.accepted_file"
                        dense
                        v-on:change="updateFileName(index)"
                        hide-details
                      ></v-file-input>
                    </td>
                    <td width="50%" class="pr-0 pl-2 py-2">
                      <v-text-field
                        placeholder="File Name"
                        label="File Name"
                        dense
                        solo
                        flat
                        color="cyan"
                        v-model="row.file_name"
                        hide-details
                        class="has-delete-outer-icon"
                        append-outer-icon="mdi-delete"
                        v-on:click:append-outer="removeRow(index)"
                        :suffix="getFileExtension(row.file_type)"
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr v-if="actionData.attachments.length < 5">
                    <td colspan="2" class="pl-0">
                      <v-btn
                        color="cyan white--text"
                        small
                        v-on:click="addAttachmentRow()"
                        class="custom-bold-button"
                        >Add More...</v-btn
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-flex>
          </v-layout>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="confirmLoading"
          v-on:click="confirmDialog = false"
          class="mx-2 custom-grey-border custom-bold-button"
        >
          No! Close
        </v-btn>
        <v-btn
          v-on:click="confirmSubmit()"
          :disabled="confirmLoading"
          :loading="confirmLoading"
          class="mx-2 custom-bold-button white--text"
          color="cyan"
        >
          Yes! Continue
        </v-btn>
      </template>
    </confirm-dialog>
    <send-mail
      :mail-dialog="mailDialog"
      title="Send as Email"
      type="invoice"
      v-on:close="mailDialog = false"
      :type-id="invoiceId"
      v-on:success="getInvoice()"
    ></send-mail>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CommonMixin from "@/core/plugins/common-mixin";
import JwtService from "@/core/services/jwt.service";
import { UPLOAD, PATCH, GET } from "@/core/services/store/request.module";
import {
  SET_VIEW_LINE_ITEM,
  SET_VIEW_CALCULATION,
} from "@/core/services/store/line.item.module";
import LineItemViewV2 from "@/view/pages/partials/Line-Item-View-V2.vue";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import Dialog from "@/view/pages/partials/Dialog";
import InternalVisit from "@/view/pages/visit/Visit-Listing-Template";
import ObjectPath from "object-path";
import SendMail from "@/view/pages/partials/Send-Mail";
/*import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import moment from "moment-timezone";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);*/

export default {
  mixins: [CommonMixin],
  data() {
    return {
      formLoading: false,
      tab: null,
      invoiceTab: "line-item",
      dbInvoice: {},
      invoiceId: 0,
      mailDialog: false,
      pageLoaded: false,
      confirmDialog: false,
      confirmLoading: false,
      confirmType: null,
      actionData: {
        remark: null,
        status: null,
        attachments: [
          {
            accepted_file: null,
            file_name: null,
            file_type: null,
          },
        ],
      },
      pageLoading: true,
    };
  },
  methods: {
    downloadFile(url) {
      // Create a temporary link element
      const link = document.createElement('a');
      link.href = url;
      link.download = ''; // Optionally, set a filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    routeToEnquiry() {
      this.$router.push(
        this.getDefaultRoute("enquiry.detail", {
          params: { id: this.dbInvoice?.enquiry },
        })
      );
    },
    routeToQuotation() {
      this.$router.push(
        this.getDefaultRoute("quotation.detail", {
          params: { id: this.dbInvoice?.quotation },
        })
      );
    },
    routeToVisit() {
      this.$router.push(
        this.getDefaultRoute("visit.detail", {
          params: { id: this.dbInvoice?.visit },
        })
      );
    },
    confirmSubmit() {
      switch (this.confirmType) {
        case "mark_as_closed":
          this.updateStatus("mark_as_closed");
          break;
        case "mark_as_cancelled":
          this.updateStatus("mark_as_cancelled");
          break;
        case "mark_as_sent":
          this.updateStatus("mark_as_sent");
          break;
        case "mark_as_pending":
          this.updateStatus("mark_as_pending");
          break;
        case "mark_as_rejected":
          this.updateStatus("mark_as_rejected");
          break;
        case "mark_as_accepted":
          this.updateStatus("mark_as_accepted");
          break;
        case "mark_as_approved":
          this.updateStatus("mark_as_approved");
          break;
      }
    },
    uploadFiles() {
      let _this = this;
      return new Promise((resolve, reject) => {
        let formData = new FormData();

        let status = false;

        for (let i = 0; i < _this.actionData.attachments.length; i++) {
          if (_this.actionData.attachments[i].accepted_file) {
            status = true;
            formData.append(
              `files[${i}]`,
              _this.actionData.attachments[i].accepted_file,
              _this.actionData.attachments[i].file_name
            );
          }
        }

        if (!status) {
          resolve([]);
        } else {
          _this.$store
            .dispatch(UPLOAD, { url: "file-manager/upload", data: formData })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    async updateStatus() {
      if (this.confirmLoading) {
        return false;
      }

      this.confirmLoading = true;

      this.actionData.status = this.confirmType;
      this.actionData.attachments = await this.uploadFiles();

      this.$store
        .dispatch(PATCH, {
          url: "invoice/" + this.invoiceId + "/status",
          data: this.actionData,
        })
        .then(() => {
          this.getInvoice();
          this.resetConfirm();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
    resetConfirm() {
      this.confirmDialog = false;
      this.confirmType = null;
      this.actionData = {
        remark: null,
        status: null,
        attachments: [
          {
            accepted_file: null,
            file_name: null,
            file_type: null,
          },
        ],
      };
    },
    updateFileName(index) {
      const file = this.actionData.attachments[index].accepted_file;
      if (file) {
        this.actionData.attachments[index].file_name = file.name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.actionData.attachments[index].file_type = file.name
          .split(".")
          .pop();
        this.addAttachmentRow();
      }
    },
    removeRow(index) {
      ObjectPath.del(this.actionData.attachments, index);
      this.$nextTick(() => {
        if (!this.actionData.attachments.length) {
          this.addAttachmentRow();
        }
      });
    },
    addAttachmentRow() {
      if (this.actionData.attachments.length < 5) {
        this.actionData.attachments.push({
          accepted_file: null,
          file_name: null,
          file_type: null,
        });
      }
    },
    getFileExtension(file_type) {
      if (file_type) {
        return `.${file_type}`;
      }
      return null;
    },
    getConfirmTitle() {
      if (this.confirmType == "mark_as_closed") {
        return "Mark as Lost";
      } else if (this.confirmType == "mark_as_cancelled") {
        return "Mark as Cancelled";
      } else if (this.confirmType == "mark_as_sent") {
        return "Mark as Send";
      } else if (this.confirmType == "mark_as_pending") {
        return "Mark as Pending";
      } else if (this.confirmType == "mark_as_rejected") {
        return "Mark as Rejected";
      } else if (this.confirmType == "mark_as_accepted") {
        return "Mark as Accepted";
      } else if (this.confirmType == "mark_as_approved") {
        return "Mark as Approved";
      }
    },
    updateMoreAction(action) {
      this.resetConfirm();
      switch (action) {
        case "edit":
          this.editInvoice();
          break;
        case "convert_visit":
          this.routeToJob();
          break;
        case "signature_link":
          this.getSignatureLink();
          break;
        case "duplicate":
          this.routeToDuplicate();
          break;
        case "mark_as_closed":
          this.confirmDialog = true;
          this.confirmType = "mark_as_closed";
          break;
        case "mark_as_cancelled":
          this.confirmDialog = true;
          this.confirmType = "mark_as_cancelled";
          break;
        case "mark_as_sent":
          this.confirmDialog = true;
          this.confirmType = "mark_as_sent";
          break;
        case "send_as_email":
          this.mailDialog = true;
          break;
        case "mark_as_pending":
          this.confirmDialog = true;
          this.confirmType = "mark_as_pending";
          break;
        case "mark_as_rejected":
          this.confirmDialog = true;
          this.confirmType = "mark_as_rejected";
          break;
        case "mark_as_accepted":
          this.confirmDialog = true;
          this.confirmType = "mark_as_accepted";
          break;
        case "mark_as_approved":
          this.confirmDialog = true;
          this.confirmType = "mark_as_approved";
          break;
        case "download_pdf":
          this.downloadPDF();
          break;
        case "print":
          this.printPDF();
          break;
      }
    },
    editInvoice() {
      this.$router.push(
        this.getDefaultRoute("invoice.update", {
          query: { invoice: this.invoiceId },
        })
      );
    },
    routeToJob() {
      this.$router.push(
        this.getDefaultRoute("job.create", {
          query: { invoice: this.invoiceId },
        })
      );
    },
    routeToDuplicate() {
      this.$router.push(
        this.getDefaultRoute("invoice.create", {
          query: { duplicate: this.invoiceId },
        })
      );
    },
    routeToCustomer() {
      //
    },
    downloadPDF() {
      window.open(this.dbInvoice?.pdf_url, "_blank");
    },
    printPDF() {
      const token = JwtService.getToken();
      const url = `${this.$apiURL}invoice-v1/${this.invoiceId}/pdf?token=${token}&action=print`;
      window.open(url, "_blank");
    },
    getInvoice() {
      this.$store
        .dispatch(GET, { url: "invoice-v1/" + this.invoiceId })
        .then(({ data }) => {
          this.dbInvoice = data;

          this.$store.dispatch(SET_VIEW_CALCULATION, {
            ctx_discount_label: this.dbInvoice?.ctx_discount_label ?? null,
            ctx_discount_value: this.dbInvoice?.ctx_discount_value ?? 0,
            ctx_discount: this.dbInvoice?.ctx_discount ?? 0,
            ctx_discount_type: this.dbInvoice?.ctx_discount_type ?? 1,
            sub_total: this.dbInvoice?.sub_total ?? 1,
            discount_type: this.dbInvoice?.discount_type ?? 1,
            discount_value: this.dbInvoice?.discount_value ?? 0,
            discount_amount: this.dbInvoice?.discount_amount ?? 0,
            tax_active: this.dbInvoice?.tax_active ?? false,
            tax_value: this.dbInvoice?.tax_value ?? 0,
            tax_amount: this.dbInvoice?.tax_amount ?? 0,
            adjustment: this.dbInvoice?.adjustment ?? 0,
            total: this.dbInvoice?.total ?? 0,
            show_price: true,
            discount_level: this.dbInvoice?.discount_level ?? "transaction",
          });

          this.$store.dispatch(SET_VIEW_LINE_ITEM, data.line_item);
          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Invoice", route: "invoice" },
            { title: "Detail" },
            { title: data.barcode },
          ]);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.pageLoaded = true;
          this.pageLoading = false;
        });
    },
  },
  created() {
    this.invoiceId = this.lodash.toSafeInteger(this.$route.params.id);
    this.invoiceTab = this.$route.query.tab ?? "overview";
  },
  mounted() {
    this.getInvoice();
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 40);
    },
  },
  components: {
    "line-item-view": LineItemViewV2,
    "internal-history-detail": InternalHistoryDetail,
    "confirm-dialog": Dialog,
    "internal-visit": InternalVisit,
    "send-mail": SendMail,
  },
};
</script>
<style lang="scss" scoped>
.invoice-detail-page {
  border-top: solid 8px #f7941e;
  label {
    padding-left: 5px;
    font-weight: 600;
  }
  p {
    padding-left: 5px;
  }
}
</style>
